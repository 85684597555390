import { OrgId } from "@ollie-sports/models";
import { getBifrost } from "../services/bifrost.service";

export function useOrgTeamTags(p: { orgId: OrgId }) {
  const orgTeamTags = getBifrost().orgTeamTag__client__getTeamTagsForOrgSubscription.useClientSubscription(
    {
      orgId: p.orgId
    },
    { notifyOnMetaDataChanges: true }
  );

  return orgTeamTags.data;
}
