import { TeamWithSquad } from "@ollie-sports/models";
import { useRef, useEffect } from "react";
import { getBifrost } from "../services/bifrost.service";
import { getCurrentUserAccountId } from "./commonDataUtils";
import { usePersistentState } from "../utils/usePersistentState";

export type GloballySelectedTeamsWithSquad = {
  selectedTeamsWithSquad: TeamWithSquad[];
  setSelectedTeamsWithSquad: (newTeams: TeamWithSquad[]) => void;
  allTeamsWithSquad: TeamWithSquad[] | undefined;
};

export function useGloballySelectedTeamsWithSquad(): GloballySelectedTeamsWithSquad | null {
  const { data: allTeamsWithSquad } = getBifrost().trends__server__getTeamsWithSquadOptions.useServer({
    accountId: getCurrentUserAccountId()
  });

  const [selectedTeamIds, setSelectedTeamIds] = usePersistentState<Record<string, true>>({
    initialValue: {},
    key: "GLOBAL_SELECTED_TEAMS"
  });

  useEffect(() => {
    if (!!allTeamsWithSquad && Object.keys(selectedTeamIds).length === 0) {
      setSelectedTeamIds(
        allTeamsWithSquad.reduce((acc, a) => {
          acc[a.teamIdWithSquad] = true;
          return acc;
        }, {} as Record<string, true>)
      );
    }
  }, [!!allTeamsWithSquad]);

  if (!allTeamsWithSquad) {
    return null;
  }

  return {
    selectedTeamsWithSquad: allTeamsWithSquad ? allTeamsWithSquad.filter(t => selectedTeamIds[t.teamIdWithSquad]) : [],
    setSelectedTeamsWithSquad: (newTeams: TeamWithSquad[]) => {
      setSelectedTeamIds(Object.fromEntries(newTeams.map(t => [t.teamIdWithSquad, true])));
    },
    allTeamsWithSquad
  };
}
