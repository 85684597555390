import { OrgId } from "@ollie-sports/models";
import { getBifrost } from "../services/bifrost.service";
import { useOrgSeasons } from "./useOrgSeasons";

export function useOrgRegistrationPackages(p: { orgId: OrgId; includeArchived?: boolean }) {
  const orgSeasons = useOrgSeasons(p);
  const { data: orgRegistrationPackages } =
    getBifrost().orgRegistrationPackage__client__getOrgRegistrationPackagesForOrgSubscription.useClientSubscription(
      {
        orgId: p.orgId
      },
      { notifyOnMetaDataChanges: true }
    );

  return orgRegistrationPackages?.filter(ofp => !!orgSeasons?.find(os => os.id === ofp.orgSeasonId));
}
