import { OrgId } from "@ollie-sports/models";
import { getBifrost } from "../services/bifrost.service";

export function useOrgSettings(p: { orgId: OrgId }) {
  const { data: orgSettings, isLoading } = getBifrost().orgSettings__client__getOrgSettingsSubscription.useClientSubscription(
    {
      orgId: p.orgId
    },
    { notifyOnMetaDataChanges: true }
  );

  return { orgSettings: orgSettings ?? undefined, isLoading };
}
