import { TeamId } from "@ollie-sports/models";
import { getBifrost } from "../services/bifrost.service";

export function useRecentOpponents(p: { teamId: TeamId | undefined; enabled?: boolean }): string[] | null {
  const { data: opponenentNames } = getBifrost().calendarEntry__server__recentOpponents.useServer(
    { teamId: p.teamId || "" },
    { skip: !p.teamId }
  );

  if (!opponenentNames) {
    return null;
  } else {
    return opponenentNames.sort();
  }
}
