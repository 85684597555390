import { OrgId } from "@ollie-sports/models";
import { getBifrost } from "../services/bifrost.service";

export function useOrgPaymentPlans(p: { orgId: OrgId }) {
  const { data: orgPaymentPlans } = getBifrost().orgPaymentPlan__client__getOrgPaymentPlansSubscription.useClientSubscription(
    {
      orgId: p.orgId
    },
    { notifyOnMetaDataChanges: true }
  );

  return orgPaymentPlans;
}
