import _ from "lodash";
import { useRef, useState } from "react";
import { useDebouncedFn } from "../utils/hooks/useDebouncedFn";

//A sledgehammer for ALWAYS detecting an elements boundingClientRectangle. Does a very blunt job of it with a million subscriptions.
export function useBoundingClientRect<T extends HTMLElement>(): { ref: { current: null | T }; rect: null | DOMRect } {
  const [boundingClientRect, setBoundingClientRect] = useState<null | DOMRect>(null);
  const meta = useRef({
    mutationObserver: null as null | MutationObserver,
    resizeObserver: null as null | MutationObserver,
    unsubscribeWindowObserver: null as null | (() => void),
    elm: null as null | T
  });

  const update = useDebouncedFn({
    deps: [],
    fn: () => {
      const thisElm = meta.current.elm;

      if (!thisElm) {
        return;
      }

      const newRect = thisElm.getBoundingClientRect();

      if (_.isEqual(thisElm, newRect)) {
        return;
      }

      setBoundingClientRect(newRect);
    },
    ms: 20
  });

  const elmRef = useRef({
    get current() {
      return meta.current.elm;
    },

    set current(elm: T | null) {
      meta.current.elm = elm;
      if (elm) {
        setBoundingClientRect(elm.getBoundingClientRect());
        meta.current.mutationObserver = new window.MutationObserver(update);
        meta.current.mutationObserver.observe(elm, { attributes: true, childList: true, subtree: true });

        //@ts-ignore
        if (window.ResizeObserer) {
          //@ts-ignore
          meta.current.resizeObserver = new window.ResizeObserver(update);
          //@ts-ignore
          meta.current.resizeObserver.observe(elm);
        }

        window.addEventListener("resize", update);
        meta.current.unsubscribeWindowObserver = () => {
          window.removeEventListener("resize", update);
        };
      } else {
        meta.current.resizeObserver?.disconnect();
        meta.current.mutationObserver?.disconnect();
        meta.current.unsubscribeWindowObserver?.();
      }
    }
  });

  return { ref: elmRef.current, rect: boundingClientRect };
}
