import { OrgId, Team, TeamId, TeamSettings } from "@ollie-sports/models";
import { getBifrost } from "../services/bifrost.service";
import { useOrgTeams } from "./useOrgTeams";

export function useOrgTeamsWithSettingsByTeamId(p: { orgId: OrgId }) {
  const teams = useOrgTeams({ orgId: p.orgId });

  const { data: teamSettings } = getBifrost().teams__client__getSettingsForMultipleTeamsSubscriptions.useClientSubscription({
    teamIds: teams?.map(a => a.id) || []
  });

  const teamSettingsMap = teamSettings?.reduce((acc, b) => {
    acc[b.id] = b;
    return acc;
  }, {} as Record<string, TeamSettings>);

  if (!teams || !teamSettings) {
    return null;
  }

  return teams.reduce((acc, b) => {
    acc[b.id] = { team: b, settings: teamSettingsMap![b.id] };

    return acc;
  }, {} as Record<TeamId, { team: Team; settings: TeamSettings }>);
}
