import { MutableRefObject, useEffect, useRef } from "react";

let callbacks = new Set<{
  ref: any;
  fn: (e: any) => void;
}>();

const handleClick = (e: any) => {
  callbacks.forEach(callback => {
    const elm = document.contains(e.target) ? e.target : document.elementFromPoint(e.clientX, e.clientY); //Only use the mouse click element if it's still attached to the DOM

    if (callback.ref.current && !callback.ref.current?.contains(elm)) {
      callback.fn(e);
    }
  });
};

let hasAdded = false;
const lazilyAddDocumentListener = () => {
  if (!hasAdded) {
    document.addEventListener("click", handleClick);
    hasAdded = true;
  }
};

export function useOnOutsideClick<T extends HTMLElement>(p: {
  callback: (e: MouseEvent) => void;
  deps: any[]; //Change these when the callback should be updated
  enabled: boolean; //Whether outside clicks should even be detected
}) {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (p.enabled) {
      lazilyAddDocumentListener();
      const val = { ref, fn: p.callback };
      callbacks.add(val);

      return () => {
        callbacks.delete(val);
      };
    }
  }, [p.enabled, ...p.deps]);

  return ref;
}
