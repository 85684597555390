import { OrgId } from "@ollie-sports/models";
import { getBifrost } from "../services/bifrost.service";

export function useOrgSeasons(p: { orgId: OrgId; includeArchived?: boolean }) {
  const { data: orgSeasons } = getBifrost().orgSeason__client__getOrgSeasonsForOrgSubscription.useClientSubscription(
    {
      orgId: p.orgId
    },
    { notifyOnMetaDataChanges: true }
  );

  return p.includeArchived ? orgSeasons : orgSeasons?.filter(os => !os.archivedAtMS);
}
