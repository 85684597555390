import { PrettyPlayer } from "@ollie-sports/models";
import { getBifrost } from "../services/bifrost.service";
import firebase from "firebase";
import { getGlobalState } from "../services/global-state.service";
import React from "react";
import { compute } from "@ollie-sports/core";

// Little helper function that will throw an error if there is no accountId set
export function getCurrentUserAccountId() {
  const currentUserUid = getGlobalState().auth.uid;
  if (!currentUserUid) {
    throw Error("Expecting to find logged in current user but it is not available");
  }

  return currentUserUid;
}

// Will not throw if the there is no current user
export function getCurrentUserAccountIdNoThrow() {
  const currentUserUid = getGlobalState().auth.uid;
  if (!currentUserUid) {
    return "";
  }

  return currentUserUid;
}

export function useCurrentUserTeams() {
  const teamsOrgs = getBifrost().teams__client__teamsOrgSubscription.useClientSubscription({
    selfAccountId: getCurrentUserAccountId()
  });

  return {
    ...teamsOrgs,
    data: teamsOrgs.data ? teamsOrgs.data.teams : undefined
  };
}

export function useCurrentUserTeamsWithSkip(skip: boolean) {
  const teamsOrgs = getBifrost().teams__client__teamsOrgSubscription.useClientSubscription(
    {
      selfAccountId: getCurrentUserAccountIdNoThrow()
    },
    { skip }
  );

  return {
    ...teamsOrgs,
    data: teamsOrgs.data ? teamsOrgs.data.teams : undefined
  };
}

export function useCurrentUserAdminOrgs() {
  const { data: teamAndOrgs } = useCurrentUserTeamsAndOrgs();
  return teamAndOrgs?.orgs.filter(o => compute.org.isOrgAdmin(teamAndOrgs?.orgs ?? [], getCurrentUserAccountId(), o.id));
}

export function useCurrentUserTeamsAndOrgs() {
  const { data } = getBifrost().teams__client__teamsOrgSubscription.useClientSubscription({
    selfAccountId: getCurrentUserAccountId()
  });
  return { data };
}

export function useCurrentUserManagedPrettyPlayerBundles(deps?: any[]) {
  return getBifrost().playerBundle__client__managedPrettyPlayerBundlesSubscription.useClientSubscription(
    {
      selfAccountId: getCurrentUserAccountIdNoThrow()
    },
    { enabled: !!getCurrentUserAccountIdNoThrow }
  );
}

export function useCurrentUserActiveManagedPrettyPlayers() {
  const { data, ...rest } = useCurrentUserManagedPrettyPlayerBundles();

  if (!data) {
    return { data, ...rest };
  }

  const prettyPlayers = data.reduce((acc, pBundle) => {
    const linked = pBundle.playerBundle.derived.linkedPlayers;
    Object.keys(linked).forEach(pid => {
      if (linked[pid].status === "inactive") {
        return;
      }

      acc.push({
        player: {
          id: pid,
          createdAtMS: Date.now(),
          teamId: linked[pid].teamId,
          virtualAthleteAccount: pBundle.derived.accountInfo,
          jerseyNumber: "00", //TODO: Have Kevin add the player jersey number to PlayerBundle.derived.linkedPlayers
          linkedPlayerBundleId: pBundle.playerBundle.id,
          deletedAtMS: 0
        },
        derived: {
          accountInfo: pBundle.derived.accountInfo,
          playerBundle: pBundle.playerBundle,
          accountInfoSource: pBundle.derived.accountInfoSource
        }
      });
    });

    return acc;
  }, [] as PrettyPlayer[]);

  return { data: prettyPlayers, ...rest };
}

export function useCurrentUserAccount() {
  return getBifrost().account__client__accountSubscription.useClientSubscription(
    {
      accountId: getCurrentUserAccountId()
    },
    {}
  );
}

export function useCurrentUserAccountWithSkip(skip: boolean) {
  return getBifrost().account__client__accountSubscription.useClientSubscription(
    {
      accountId: getCurrentUserAccountIdNoThrow()
    },
    {
      skip
    }
  );
}

export function useCurrentUserAccountPrivate() {
  if (!getCurrentUserAccountId()) {
    throw new Error("Unable to useCurrentAccountPrivate if not logged in");
  }

  return getBifrost().account__client__accountPrivateSubscription.useClientSubscription({
    accountId: getCurrentUserAccountId()
  });
}

export function isLoggedIn() {
  return !!firebase.auth().currentUser;
}
