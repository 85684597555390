import { AccountId } from "@ollie-sports/models";
import { getBifrost } from "../services/bifrost.service";
import _ from "lodash";

export function useAccounts(p: { accountIds: AccountId[] }) {
  const { data: accounts, isLoading } = getBifrost().account__client__accountsSubscription.useClientSubscription(
    {
      accountIds: p.accountIds
    },
    {
      notifyOnMetaDataChanges: true
    }
  );
  return { accounts: _.compact(accounts), isLoading };
}
