import { OrgId, TeamId } from "@ollie-sports/models";
import { getBifrost } from "../services/bifrost.service";

export function useTeam(p: { teamId: TeamId }) {
  const { data: team, isLoading } = getBifrost().teams__client__getTeamSubscription.useClientSubscription(
    {
      teamId: p.teamId
    },
    { notifyOnMetaDataChanges: true }
  );

  return { team: team ?? undefined, isLoading };
}
