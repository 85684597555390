import { dequal } from "dequal";
import { useEffect, useRef } from "react";

//A lot cheaper than stringifying to detect changes in objects
export function useObjectVersion(obj: object) {
  const version = useRef(1);
  const prevValue = useRef<object | null>(null);

  useEffect(() => {
    prevValue.current = obj;
  });

  if (prevValue.current && !dequal(prevValue.current, obj)) {
    version.current++;
  }

  return version.current;
}
