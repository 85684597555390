import { GoogleAddress, TeamId } from "@ollie-sports/models";
import { getBifrost } from "../services/bifrost.service";

export function useRecentLocations(p: { teamId: TeamId | undefined }): GoogleAddress[] {
  const { data: locations } = getBifrost().calendarEntry__server__recentLocations.useServer(
    { teamId: p.teamId || "" },
    { skip: !p.teamId }
  );

  if (!locations) {
    return [];
  } else {
    return locations;
  }
}
