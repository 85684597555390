import { useState, useRef } from "react";
import { produce } from "immer";
import { ImmutableStateSetter } from "../utils/useImmutableState";

export function useSynchronousState<T>(initVal: T): [React.MutableRefObject<T>, (newVal: T) => void] {
  const [__, forceRerender] = useState(false);

  const val = useRef(initVal);

  function setVal(newVal: T) {
    val.current = newVal;
    forceRerender(a => !a);
  }

  return [val, setVal];
}

export function useImmutableSynchronousState<T extends Record<string, any>>(
  initValue: T
): [React.MutableRefObject<T>, ImmutableStateSetter<T>] {
  const [__, forceRerender] = useState(false);
  const val = useRef(initValue);

  function setImmerState(partialOrChangeFn: Partial<T> | ((a: T) => void)): void {
    let nextState: T;
    if (typeof partialOrChangeFn === "function") {
      nextState = produce(val.current, partialOrChangeFn) as any;
    } else {
      nextState = Object.assign({}, val.current, partialOrChangeFn);
    }

    val.current = nextState;

    forceRerender(a => !a);
  }

  return [val, setImmerState];
}
