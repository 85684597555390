import { RefObject, useEffect } from "react";

export function useElmRefListener<T extends HTMLElement, K extends keyof HTMLElementEventMap>(
  ref: RefObject<T>,
  event: K,
  listener: (e: HTMLElementEventMap[K]) => void,
  deps: any[],
  opts?: boolean | AddEventListenerOptions
) {
  useEffect(() => {
    const elm = ref?.current;
    const currListener = listener;
    elm?.addEventListener(event, currListener, opts);

    return () => {
      elm?.removeEventListener(event, currListener, opts);
    };
  }, deps);
}
