import { useEffect } from "react";

export function useKeyPress(p: { callback: (e: KeyboardEvent) => void; keys: string[]; deps: any[]; enabled?: boolean }) {
  useEffect(() => {
    if (p.enabled) {
      const listener = (event: KeyboardEvent) => {
        if (p.keys.includes(event.key)) {
          p.callback(event);
        }
      };
      document.addEventListener("keydown", listener);

      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
  }, [p.enabled, ...p.deps, ...p.keys]);
}
