import { OrgId } from "@ollie-sports/models";
import { getBifrost } from "../services/bifrost.service";

export function useOrg(p: { orgId: OrgId; enabled?: boolean }) {
  const { data: org, isLoading } = getBifrost().org__client__getOrgSubscription.useClientSubscription(
    {
      orgId: p.orgId
    },
    { notifyOnMetaDataChanges: true, enabled: p.enabled }
  );

  return { org: org ?? undefined, isLoading };
}

export function useAllOrgInfo(p: { orgId: OrgId; enabled?: boolean }) {
  const { data: orgInfo, isLoading } = getBifrost().org__client__getAllOrgInfoSubscription.useClientSubscription(
    {
      orgId: p.orgId
    },
    { notifyOnMetaDataChanges: true, enabled: p.enabled }
  );

  return { orgInfo: orgInfo, isLoading };
}
