import { useRef, useEffect, useLayoutEffect } from "react";

export function useDidUpdateEffect(fn: Parameters<typeof useEffect>[0], inputs: Parameters<typeof useEffect>[1]) {
  const prevInputs = useRef(inputs);

  useEffect(() => {
    let ret;

    if (inputs && inputs.some((a, i) => inputs[i] !== prevInputs.current?.[i])) {
      ret = fn();
    }

    prevInputs.current = inputs;

    return ret;
  }, inputs);
}

export function useDidUpdateLayoutEffect(fn: Parameters<typeof useEffect>[0], inputs: Parameters<typeof useEffect>[1]) {
  const prevInputs = useRef(inputs);

  useLayoutEffect(() => {
    let ret;

    if (inputs && inputs.some((a, i) => inputs[i] !== prevInputs.current?.[i])) {
      ret = fn();
    }

    prevInputs.current = inputs;

    return ret;
  }, inputs);
}
