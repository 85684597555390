import _ from "lodash";
import { useLocation, useHistory } from "react-router";

export type SetSearchParamsOptions = { shouldAddNewBrowserNavigationEntry?: boolean; replaceState?: boolean };

export function useSearchParamsState<T extends object>() {
  const location = useLocation();
  const history = useHistory();

  const params = _.mapValues(Object.fromEntries(new URLSearchParams(location.search).entries()), a => {
    if (a.match(/%/)) {
      return JSON.parse(decodeURIComponent(a));
    }

    return a.match(/^(\d+\.?\d*|true|false)$/) ? JSON.parse(a) : a;
  }) as any as Partial<T>;

  return [
    params,
    (paramsPartial: { [k in keyof T]?: string }, opts?: SetSearchParamsOptions) => {
      const method = opts?.shouldAddNewBrowserNavigationEntry ? "push" : "replace";

      const nextParams = _.omitBy(opts?.replaceState ? paramsPartial : { ...params, ...paramsPartial }, a => !a);

      Object.keys(nextParams).forEach(k => {
        const val = nextParams[k];
        if (typeof val === "object") {
          nextParams[k] = encodeURIComponent(JSON.stringify(val));
        }
      });

      history[method]({
        pathname: location.pathname,
        search: new URLSearchParams(nextParams as any).toString()
      });
    }
  ] as const;
}
